@media (max-width: 700px) {
    #contact-page { max-width: calc(100vw - 100px); }
    form { width: calc(100vw + 100px); }
}

.section {
    display: flex;
    flex-direction: column;
}

form {
    width: 100%; 
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 8px; 
}

form.submitting .section { opacity: 0.45; }

label { font-size: 16px; }

input, textarea {
    font-size: 18px;
    padding: 4px;
    font-family: "Inter", sans-serif;
}

textarea {
    height: 100px;
    margin-bottom: 10px;
}

input[type="submit"].disabled {
    opacity: 0.35;
}

input[type="submit"] {
    color: white;
    background: black;
    border: none;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    font-family: sans-serif;
}

input[type="submit"].enabled:hover {
    background: #333;
}

.form-status {
    margin-top: 10px;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
}

.form-status.error {
    background-color: #d5000020;
    color: #d50000;
}

.form-status.success {
    background-color: #00800020;
    color: #008000;
}

// @media (max-width: 700px) {
//     form { max-width: calc(100vw - 100px); }
// }