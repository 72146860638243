#page-content {
    background: #fff;
    padding: 50px 75px;
    border-radius: 50px;
    margin-bottom: 50px;
}

.flex { 
    width: 100%; display: flex; gap: 60px; 
    
    > div:first-child { width: 60%; }
    > div p:first-of-type { margin-top: 0; }
    > div:last-child { width: 40%; }
}

.flex.reverse {
    div:first-child { width: 40%; }
    div:last-child { width: 60%; }
}

#company-page {
    #company-photos {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }
    figure img { border-radius: 6px; }
    figcaption { text-align: center; }
}

#error-page {
    a { text-decoration: underline; }
}

@media (max-width: 1100px) {
    .flex { flex-direction: column; gap: 0; }
    .flex.reverse { flex-direction: column-reverse; }
    .flex > div:first-child, .flex > div:last-child, .flex.reverse > div:first-child, .flex.reverse > div:last-child { width: 100%; }
    #company-page #company-photos { justify-content: space-around; }
    #contact-page .flex { display: initial; }
    #contact-image { display: none; }
}

@media (max-width: 700px) {
    #page-content {
        padding: 20px 30px;
        margin-bottom: 20px;
        border-radius: 30px;
    }
}

@media (max-width: 500px) {
    #company-page #company-photos {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
    }
}