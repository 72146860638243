footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 15px;
    color: #666;
    
    a { color: #666; }
}

footer .logo {
    width: 96px;
    height: 30px;
    width: auto;
    max-width: 96px;
}

#footer-contact {
    display: flex;
    gap: 10px;
}

@media (max-width: 700px) {
    footer {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    #footer-contact {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .dot { display: none; }
}