@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

html { overflow-y: scroll; }

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;
    background: #f3f3f3;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #7d7d7d;
    text-decoration: underline;
}

h1 { 
    font-size: 32px;
    line-height: 46px;
    margin: 0 0 30px 0;
}

h3 { margin: 26px 0 12px 0; }

figure { margin: 0; }

p { text-align: justify; }

img { width: 100%; border-radius: 6px; }

#root {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 40px;
}

@media (max-width: 700px) {
    #root { padding: 0 12px; }
    h1 { font-size: 28px; line-height: 36px; margin: 10px 0 20px 0; }
    h1, h3 { font-family: sans-serif; }
    p { text-align: initial; }
}