header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 40px 0;
}

header a { white-space: nowrap; }

header a.active {
    color: #7d7d7d;
}

header .logo {
    height: 50px;
    width: 161px;
}

header nav {
    display: flex;
    gap: 22px;
}

#nav-menu { position: relative; }
#nav-menu-button { 
    display: none;
    width: 32px;
    height: 32px;
    margin-top: -4px;
    background-image: url('../media/menu.svg');
    background-size: contain;
    cursor: pointer;

    &:active { opacity: 0.75; }
}

@media (max-width: 1100px) {
    header #nav-menu-button { display: block; }
    header nav { 
        display: none;
        position: absolute;
        top: 28px;
        right: 0;
        flex-direction: column;
        padding: 18px;
        background-color: #fff;
        border: 1px solid #c8ccd1;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
        font-size: 20px;
        line-height: 34px;
    }
    header #nav-menu.open nav { display: flex; }
}